import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  SectionStyled,
  TitleContainer,
  TitleH1,
  ContentContainer,
  Bold,
  Mail,
} from "../PrivacySection/PrivacyStyle"

function SgReferralTncMain({ props, propsLink, status }) {
  const isEnglish = propsLink.MainPage.includes("/en")
  const {
    Header,
    Article1,
    Article2,
    Article3,
    Article4,
    Article5,
    Article6,
    Article7,
    Article8,
    Article9,
    Article10,
  } = props

  const LinkToService = isEnglish
    ? `/en/sg/service-agreement`
    : `/sg/service-agreement`
  const LinkToPrivacy = isEnglish
    ? `/en/sg/privacy-policy`
    : `/sg/privacy-policy`
  const LinkToAffiliate = isEnglish ? `/en/affiliate` : `/affiliate`
  const LinkToContactUs = isEnglish ? `/en/contact-us` : `/contact-us`

  const OrderedLine = ({ children, type }) => {
    return (
      <ol
        type={type}
        style={{
          paddingInlineStart: "20px",
          fontFamily: "GothamBook",
        }}
      >
        {children}
      </ol>
    )
  }

  const UnorderedLine = ({ children, type }) => {
    return (
      <ul
        style={{
          paddingInlineStart: "20px",
          fontFamily: "GothamBook",
        }}
      >
        {children}
      </ul>
    )
  }
  return (
    <>
      <section className="content">
        <ContentContainer>
          <ContentP>
            {Header.Desc1}
            <NewLink to={LinkToService}>{Header.Desc2}</NewLink> {Header.Desc3}
            <NewLink to={LinkToPrivacy}>{Header.Desc4}</NewLink> {Header.Desc5}
            <NewLink to={LinkToService}>{Header.Desc2}</NewLink> {Header.Desc3}
            <NewLink to={LinkToPrivacy}>{Header.Desc4}</NewLink>. {Header.Desc6}
          </ContentP>

          {/* NOMOR 1 */}
          <PointH3>{Article1.Title}</PointH3>
          <OrderedLine type="1">
            <ContentLiBold>{Article1.Point1}</ContentLiBold>
            <ContentP>{Article1.Desc1}</ContentP>
            <ContentLiBold>{Article1.Point2}</ContentLiBold>
            <ContentP>
              {Article1.Desc2}{" "}
              <NewLink to={LinkToService}>{Article6.Point2a}</NewLink>
              {Header.Desc3}
              <NewLink to={LinkToPrivacy}>{Article6.Point2c}</NewLink>.
            </ContentP>
            <ContentLiBold>{Article1.Point3}</ContentLiBold>
            <ContentP>{Article1.Desc3}</ContentP>
            <ContentLiBold>{Article1.Point4}</ContentLiBold>
            <ContentP>{Article1.Desc4}</ContentP>
            <ContentP>{Article1.Desc4a}</ContentP>
            <ContentP>{Article1.Desc4b}</ContentP>
            <ContentLiBold>{Article1.Point5}</ContentLiBold>
            <ContentP>{Article1.Desc5}</ContentP>
          </OrderedLine>

          {/* NOMOR 2 */}
          <PointH3>{Article2.Title}</PointH3>
          <ContentP>{Article2.Desc}</ContentP>
          <OrderedLine type="1">
            <ContentLiBold>{Article2.Point1}</ContentLiBold>
            <ContentP>{Article2.Desc1}</ContentP>
            {/* <NewLink to={LinkToAffiliate}>
                </NewLink> */}
            <ContentP> {Article2.Desc1a}</ContentP>
            <ContentLiBold>{Article2.Point2}</ContentLiBold>
            <ContentP>{Article2.Desc2}</ContentP>
            <ContentLiBold>{Article2.Point3}</ContentLiBold>
            <ContentP>{Article2.Desc3}</ContentP>
            <ContentLiBold>{Article2.Point4}</ContentLiBold>
            <ContentP>{Article2.Desc4}</ContentP>
            <ContentP>{Article2.Desc4a}</ContentP>
            <ContentLiBold>{Article2.Point5}</ContentLiBold>
            <ContentP>{Article2.Desc5}</ContentP>
            <ContentLiBold>{Article2.Point6}</ContentLiBold>
            <ContentP>{Article2.Desc6}</ContentP>
            <ContentLiBold>{Article2.Point7}</ContentLiBold>
            <ContentP>{Article2.Desc7}</ContentP>
            <ContentLiBold>{Article2.Point8}</ContentLiBold>
            <ContentP>{Article2.Desc8}</ContentP>
            <ContentLiBold>{Article2.Point9}</ContentLiBold>
            <ContentP>{Article2.Desc9}</ContentP>
            <ContentLiBold>{Article2.Point10}</ContentLiBold>
            <ContentP>{Article2.Desc10}</ContentP>
            <ContentLiBold>{Article2.Point11}</ContentLiBold>
            <ContentP>{Article2.Desc11}</ContentP>
            <ContentLiBold>{Article2.Point12}</ContentLiBold>
            <ContentP>{Article2.Desc12}</ContentP>
            <ContentP>{Article2.Desc12a}</ContentP>
            <OrderedLine type="a">
              <ContentLi>{Article2.Desc13}</ContentLi>
              <UnorderedLine>
                <ContentLi>{Article2.Desc13a}</ContentLi>
                <ContentLi>{Article2.Desc13b}</ContentLi>
                <ContentLi>{Article2.Desc13c}</ContentLi>
                <ContentLi>{Article2.Desc13d}</ContentLi>
              </UnorderedLine>
              <ContentP>
                <span style={{ fontFamily: "GothamMedium" }}>
                  {Article2.Desc13e}
                </span>
                {Article2.Desc13f}
              </ContentP>
              <ContentLi>{Article2.Desc14}</ContentLi>
              <UnorderedLine>
                <ContentLi>{Article2.Desc14a}</ContentLi>
                <ContentLi>{Article2.Desc14b}</ContentLi>
                <ContentLi>{Article2.Desc14c}</ContentLi>
                <ContentLi>{Article2.Desc14d}</ContentLi>
              </UnorderedLine>
            </OrderedLine>
          </OrderedLine>

          {/* NOMOR 3 */}
          <PointH3>{Article3.Title}</PointH3>
          <ContentP>{Article3.Desc}</ContentP>
          <OrderedLine type="1">
            <ContentLi>{Article3.Point1}</ContentLi>
            <ContentLi>{Article3.Point2}</ContentLi>
            <ContentLi>{Article3.Point3}</ContentLi>
            <ContentLi>{Article3.Point4}</ContentLi>
            <ContentLi>{Article3.Point5}</ContentLi>
            <ContentLi>{Article3.Point6}</ContentLi>
            <ContentLi>{Article3.Point7}</ContentLi>
            <ContentLi>{Article3.Point8}</ContentLi>
            <ContentLi>{Article3.Point9}</ContentLi>
            <ContentLi>{Article3.Point10}</ContentLi>
          </OrderedLine>
          <ContentP>{Article3.Point11}</ContentP>

          {/* NOMOR 4 */}
          <PointH3>{Article4.Title}</PointH3>
          <OrderedLine type="1">
            <ContentLi>{Article4.Point1}</ContentLi>
            <ContentLi>{Article4.Point2}</ContentLi>
          </OrderedLine>

          {/* NOMOR 5 */}
          <PointH3>{Article5.Title}</PointH3>
          <ContentP>{Article5.Desc}</ContentP>

          {/* NOMOR 6 */}
          <PointH3>{Article6.Title}</PointH3>
          <OrderedLine type="1">
            <ContentLi>{Article6.Point1}</ContentLi>
            <ContentLi>
              {Article6.Point2}{" "}
              <NewLink to={LinkToService}>{Article6.Point2a}</NewLink>{" "}
              {Article6.Point2b}
              <NewLink to={LinkToPrivacy}>{Article6.Point2c}</NewLink>{" "}
            </ContentLi>
            <ContentLi>{Article6.Point3}</ContentLi>
            <ContentLi>{Article6.Point4}</ContentLi>
          </OrderedLine>

          {/* NOMOR 7 */}
          <PointH3>{Article7.Title}</PointH3>
          <ContentP>{Article7.Desc}</ContentP>

          {/* NOMOR 8 */}
          <PointH3>{Article8.Title}</PointH3>
          <OrderedLine type="1">
            <ContentLi>{Article8.Point1}</ContentLi>
            <ContentLi>{Article8.Point2}</ContentLi>
            <ContentLi>{Article8.Point3}</ContentLi>
            <ContentLi>{Article8.Point4}</ContentLi>
            <ContentLi>{Article8.Point5}</ContentLi>
          </OrderedLine>

          {/* NOMOR 9 */}
          <PointH3>{Article9.Title}</PointH3>
          <OrderedLine type="1">
            <ContentLi>{Article9.Point1}</ContentLi>
            <ContentLi>{Article9.Point2}</ContentLi>
          </OrderedLine>

          {/* NOMOR 10 */}
          <PointH3>{Article10.Title}</PointH3>
          <ContentP>
            {Article10.Desc1}
            <NewLink to={LinkToContactUs}>{Article10.Desc1a}</NewLink>
          </ContentP>
          <ContentP>{Article10.Desc2}</ContentP>
        </ContentContainer>
      </section>
    </>
  )
}

export default SgReferralTncMain

const PointH3 = styled.h2`
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "GothamMedium";
  color: #196e97;
`

const ContentLiBold = styled.li`
  margin-bottom: 10px;
  text-align: justify;
  font-family: "GothamBook";
  font-weight: bold;
`

const ContentLi = styled.li`
  margin-bottom: 10px;
  text-align: justify;
  font-family: "GothamBook";
`

const NewLink = styled(Link)`
  color: #48a9b5;
  :hover {
    color: #48a9b5;
  }
`

const ContentP = styled.p`
  margin-top: ${p => p.mtop};
  margin-bottom: ${p => p.mbottom};
  text-align: justify;
  font-family: "GothamBook";
`
