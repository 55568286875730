import styled from "styled-components"
import { Container } from "react-bootstrap"

export const SectionStyled = styled.div`
  padding-top: 150px;
  padding-bottom: 150px;
`

export const MainDiv = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
  border-radius: 15px;
  padding: 50px;
  @media (max-width: 576px) {
    padding: 50px 30px 50px 30px;
  }
`

export const TitleContainer = styled(Container)`
  max-width: 800px;
`

export const TitleH1 = styled.h2`
  font-family: "Typefez-black";
  color: #909098;
  text-align: center;
  font-size: 50px;
  margin-bottom: ${p => p.mbottom || "3rem"};
`

export const ContentContainer = styled(Container)`
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`
export const PointH3 = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "Typefez-med";
  color: #196e97;
`
export const ContentLi = styled.li`
  margin-bottom: 20px;
  text-align: justify;
  font-family: "Typefez-reg";
`
export const ContentP = styled.p`
  text-align: justify;
  font-family: "Typefez-reg";
  padding-left: ${p => p.pleft};
  margin: ${p => p.margin};
  font-size: ${p => p.fsize};
`

export const Bold = styled.strong`
  font-family: "Typefez-med";
  text-decoration: ${p => p.decor};
`

export const Mail = styled.a`
  color: #da649f;
  :hover {
    color: #da649f;
  }
`

export const SpanBold = styled.span`
  font-family: "Typefez-med";
`

export const MainPointLi = styled.li`
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "Typefez-bold";
  color: #196e97;
`
