import React from "react"
import '../layout.css'
import { useStateIfMounted } from "use-state-if-mounted"
import { useLocation } from "@reach/router"

import { NavigationBar } from "../NavigationBar/parent"
import SgReferralTncMain from "./SgReferralTncMain"
import Footer from "../FooterComponents/Footer"

import { MainPageJson, SgReferralTncJson, PathJson } from "../location"
import Seo from "../SEOComponents/Seo"
import { LayoutTextPage } from "../LayoutTextPage/parent"

function SgReferralTnc() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const TncContent = SgReferralTncJson()
  const { Header } = TncContent || {}
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  return (
    <div className="maxwidth">
      <Seo title="sg-referral-tnc" />
      <NavigationBar />
      <LayoutTextPage title={Header.Title} secondTitle={Header.Title1}>
        <SgReferralTncMain
          props={TncContent}
          isEnglish={isEnglish}
          propsLink={PathContent}
        />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default SgReferralTnc
