import { useLocation } from "@reach/router"
import React from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import LogoBigGrey from "../../images/logo-big-grey.svg"

export const LayoutTextPage = ({
  title,
  secondTitle,
  thirdTitle,
  desc = false,
  children,
}) => {
  const location = useLocation()
  const isFaq = location.pathname.includes("faq")
  return (
    <SectionStyled>
      <TitleContainer>
        <TitleH1>{title}</TitleH1>
        <TitleH1>{secondTitle}</TitleH1>
        <TitleH1>{thirdTitle}</TitleH1>
        {desc && <Description>{desc}</Description>}
      </TitleContainer>
      <Container style={{ position: "relative" }}>
        <ImgBackground src={LogoBigGrey} alt="logo" />
        <MainDiv padding={isFaq && "50px 30px 50px 30px"}>{children}</MainDiv>
      </Container>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  padding-top: 130px;
  padding-bottom: 180px;
  position: relative;
`

const TitleContainer = styled(Container)`
  margin-bottom: 150px;
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`

const TitleH1 = styled.h1`
  font-family: "Typefez-black";
  font-size: 50px;
  color: #909098;
  text-align: center;
  padding: 0px 10%;
  margin-bottom: 0px;
  @media (max-width: 576px) {
    font-size: 42px;
  }
`

const Description = styled.p`
  color: #102c57;
  font-size: 18px;
  font-family: "Typefez-med";
  text-align: center;
  margin-top: 10px;
`

const MainDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
  border-radius: 15px;
  padding: ${p => p.padding || "50px"};
  position: relative;
  z-index: 3;
  @media (max-width: 576px) {
    padding: 50px 20px 50px 20px;
  }
`

const ImgBackground = styled.img`
  position: absolute;
  width: 1200px;
  top: -70px;
  right: -800px;
  z-index: -1;
`
